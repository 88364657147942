import React, { useContext } from "react";
import { ApiContext } from "../api/ApiProvider";
import axios from "axios";

export const UsuNuevosContext = React.createContext();

const UsuNuevosProvider = ({ children }) => {
  const [usunuevos, setUsunuevos] = React.useState(0);
  const api = useContext(ApiContext);
  function updateUsunuevos(retry = false) {
    api.get("/usuariosNuevos/cantidad", (res) => {
      if (res.data.success) {
        setUsunuevos(res.data.data);
      }
    });
  }
  return (
    <UsuNuevosContext.Provider
      value={{ usunuevos, setUsunuevos, updateUsunuevos }}
    >
      {children}
    </UsuNuevosContext.Provider>
  );
};

export default UsuNuevosProvider;
