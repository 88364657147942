import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "../agregar/AgregarFicha.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiContext } from "../../../context/api/ApiProvider";
import { toast } from "react-toastify";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { LoadContext } from "../../../context/load/LoadProvider";

const SubiraFicha = () => {
  const api = useContext(ApiContext);
  const { id } = useParams();
  const file = useRef();
  const navigate = useNavigate();
  const [archivos, setArchivos] = useState([]);
  const { setIsLoading } = useContext(LoadContext);

  useEffect(() => {
    getArchivos();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.set("archivo", file.current.files[0]);
    api.post(`/fichas/${id}/subir`, formData, (res) => {
      if (res.data.success) {
        toast.success("Archivo subido correctamente");
      } else {
        toast.error("Error subiendo archivo");
      }
      navigate("/inicio/fichas");
    });
  }
  function getArchivos() {
    api.get("/fichas/" + id + "/archivos/", (res) => {
      if (res.data.success) {
        setArchivos(res.data.archivos);
      } else {
        toast.error("Error obteniendo archivos");
      }
    });
  }

  function handleDelete(archivo) {
    setIsLoading(true);
    api.post(
      "/fichas/archivos/delete",
      { id, archivo },
      (res) => {
        if (res.data.success) {
          toast.success("Archivo eliminado correctamente");
        } else {
          toast.error("Error eliminando archivo");
        }
        getArchivos();
      },
      false
    );
  }

  const columns = [
    { header: "Nombre", accessorKey: "Nombre" },
    {
      header: "Modificar",
      cell: (cell) => {
        return (
          <>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => handleDelete(cell.row.original.Nombre)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        );
      },
    },
  ];
  const table = useReactTable({
    data: archivos,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles.background}>
      <form
        className={styles.modal + " p-3"}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3>Subir archivo a Ficha</h3>
        <div className={styles.hr}></div>
        {archivos ? (
          <>
            <label className="h6">Archivos Subidos</label>
            <div style={{ maxHeight: "25vh", overflowY: "auto" }}>
              <table className="table text-center table-striped table-hover">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {archivos ? (
                    table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <div className="w-100">No hay archivos subidos</div>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ""
        )}
        <label className="h6">Subir Nuevo</label>
        <input type="file" ref={file} className="form-control mt-3" required />
        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <button className={"btn btn-success"} type="submit">
            Enviar
          </button>
          <Link to={"/inicio/fichas"} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SubiraFicha;
