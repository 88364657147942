import React, { useContext, useEffect, useState } from "react";
import styles from "./EditarFicha.module.css";
import { Link, useOutletContext, useParams } from "react-router-dom";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRemove, faSearch } from "@fortawesome/free-solid-svg-icons";
import { ApiContext } from "../../../context/api/ApiProvider";
import { toast } from "react-toastify";

const EditarFicha = () => {
  const [data, setData] = useState({ asociados: [], noAsociados: [] });
  const [globalFilter, setGlobalFilter] = useState("");
  const { propId } = useParams();
  const api = useContext(ApiContext);
  const [buscador, setBuscador] = useState("");
  const { fichaCodigo } = useOutletContext();

  useEffect(() => {
    updateData();
  }, []);
  function updateData() {
    api.get(`/fichas/${propId}`, (res) => {
      setData(res.data);
    });
  }

  function handleAdd(id) {
    api.post(`/fichas/${propId}/add`, { id }, (res) => {
      if (!res.data.success) {
        toast.error("Error asociando al usuario");
      }
      updateData();
    });
  }

  function handleRemove(id) {
    api.post(`/fichas/${propId}/remove`, { id }, (res) => {
      if (!res.data.success) {
        toast.error("Error desasociando al usuario");
      }
      updateData();
    });
  }

  const [columnVisibility, setColumnVisibility] = useState({
    Usuario_ID: false,
    Nombre: true,
    Email: true,
  });

  const columnDefA = [
    {
      header: "ID",
      accessorKey: "Usuario_ID",
    },
    {
      header: "Nombre",
      accessorKey: "Nombre",
    },
    {
      header: "Email",
      accessorKey: "Email",
    },
    {
      header: "Quitar",
      cell: ({ row }) => (
        <>
          <button
            className="btn btn-danger"
            onClick={() => handleRemove(row.original.Usuario_ID)}
          >
            <FontAwesomeIcon icon={faRemove} />
          </button>
        </>
      ),
    },
  ];
  const columnDefN = [
    {
      header: "ID",
      accessorKey: "Usuario_ID",
    },
    {
      header: "Nombre",
      accessorKey: "Nombre",
    },
    {
      header: "Email",
      accessorKey: "Email",
    },
    {
      header: "Agregar",
      cell: ({ row }) => (
        <>
          <button
            className="btn btn-success"
            onClick={() => handleAdd(row.original.Usuario_ID)}
          >
            <FontAwesomeIcon icon={faAdd} />
          </button>
        </>
      ),
    },
  ];

  const tableAsociados = useReactTable({
    data: data.asociados,
    columns: columnDefA,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
    },
  });
  const tableNoAsociados = useReactTable({
    data: data.noAsociados,
    columns: columnDefN,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
      columnVisibility,
    },
  });
  return (
    <div className={styles.background}>
      <div className={styles.modal + " p-3"}>
        <h3>Editar Ficha {fichaCodigo}</h3>
        <div className={styles.hr + " mb-3"}></div>
        <h5>Usuarios No Asociados</h5>
        <span className="d-flex flex-row align-items-center w-100 justify-content-center mb-3">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            className="form-control w-50 ms-3"
            placeholder="Buscar"
            onChange={(e) => {
              setBuscador(e.target.value);
              if (e.target.value == "") {
                setGlobalFilter("");
              }
            }}
            value={buscador}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              setGlobalFilter(buscador);
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </span>
        <div className={styles.table + " mb-3"}>
          <table
            className={
              "table text-center table-striped table-hover h-100 " +
              styles.neuro
            }
          >
            <thead>
              {tableNoAsociados.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {data.noAsociados
                ? tableNoAsociados.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                : "No hay datos para mostrar"}
            </tbody>
          </table>
        </div>
        <h5>Usuarios Asociados</h5>
        <div className={styles.table}>
          <table
            className={
              "table text-center table-striped table-hover h-100 " +
              styles.neuro +
              " " +
              styles.table
            }
          >
            <thead>
              {tableAsociados.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {data.asociados
                ? tableAsociados.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                : "No hay datos para mostrar"}
            </tbody>
          </table>
        </div>

        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <Link to={"/inicio/fichas"} className={"btn btn-success ms-3"}>
            Confirmar
          </Link>
          <Link to={"/inicio/fichas"} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EditarFicha;
