import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./UserStyle.module.css";
import React, { useContext, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ApiContext } from "../context/api/ApiProvider";
import { toast } from "react-toastify";
import { LoadContext } from "../context/load/LoadProvider";

const Propietarios = () => {
  const [archivosExtra, setArchivosExtra] = useState([]);
  const [fichas, setFichas] = useState([]);
  const [selectedFicha, setSelectedFicha] = useState("");
  const [cuentas, setCuentas] = useState({ pesos: false, dolares: false });
  const { setIsLoading } = useContext(LoadContext);
  const api = useContext(ApiContext);

  function updateFichas() {
    setIsLoading(true);
    api.get(
      "/propietario/fichas",
      (res) => {
        setFichas(res.data.data);
        if (res.data.data) {
          setSelectedFicha(res.data.data[0].Propiedad_ID);
        }
      },
      ...[,],
      false
    );
  }
  const downloadCuenta = (tipo) => {
    let codigo = fichas.filter(
      (ficha) => ficha.Propiedad_ID == selectedFicha
    )[0].Codigo;
    api.download("/propietario/" + codigo + "/descargar/" + tipo, (res) => {
      let url = window.URL.createObjectURL(new Blob([res.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "cuenta" + tipo + codigo + ".pdf");
      link.click();
    });
  };

  const downloadArchivosExtra = (nombre) => {
    let codigo = fichas.filter(
      (ficha) => ficha.Propiedad_ID == selectedFicha
    )[0].Codigo;
    api.download("/propietario/" + codigo + "/archivo/" + nombre, (res) => {
      let url = window.URL.createObjectURL(new Blob([res.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre);
      link.click();
    });
  };

  function updateCuenta() {
    setIsLoading(true);
    api.get(
      "/propietario/cuentas?ficha=" + selectedFicha,
      (res) => {
        setIsLoading(false);
        setCuentas(res.data.cuentas);
        setArchivosExtra(res.data.archivos);
      },
      ...[,],
      false
    );
  }
  useEffect(() => {
    updateFichas();
  }, []);

  useEffect(() => {
    if (selectedFicha) updateCuenta();
  }, [selectedFicha]);

  const columns = [
    {
      header: "Nombre",
      accessorKey: "Nombre",
    },
    {
      header: "Descargar",
      cell: (cell) => (
        <div className="d-flex align-items-center justify-content-start">
          <button
            className="btn btn-success"
            onClick={() => {
              downloadArchivosExtra(cell.row.original.Nombre);
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      ),
    },
  ];
  const table = useReactTable({
    data: archivosExtra,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div className="col-12 d-flex flex-column align-items-center justify-content-center pt-3">
      <h3 className="text-center">Descargar archivos de Propietario</h3>
      <hr className="hr w-100" />
      <div className="col-12 col-md-8 col-lg-4 p-1 d-flex flex-column align-items-center">
        {" "}
        <label className="h5 pt-3">Cuenta</label>
        <select
          className="form-control"
          value={selectedFicha}
          onChange={(e) => {
            setSelectedFicha(e.target.value);
          }}
        >
          {fichas
            ? fichas.map((ficha) => (
                <option key={ficha.id} value={ficha.Propiedad_ID}>
                  {ficha.Direccion}
                </option>
              ))
            : ""}
        </select>
        <hr className="hr w-100" />
        <button
          className="btn btn-success w-100 mt-5"
          disabled={!cuentas.pesos}
          onClick={() => downloadCuenta("pesos")}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Cuenta Pesos
        </button>
        <button
          className="btn btn-success w-100 mt-5"
          disabled={!cuentas.dolares}
          onClick={() => downloadCuenta("dolares")}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Cuenta Dolares
        </button>
        <hr className="hr w-100" />
        {archivosExtra.length > 0 ? (
          <>
            {" "}
            <h4 className="mb-3">Archivos Extra</h4>
            <div className={"w-100 " + styles.extraTable}>
              <table className="w-100 table table-striped">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {archivosExtra
                    ? table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    : "No hay archivos para mostrar"}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Propietarios;
