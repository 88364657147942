import React, { useEffect } from "react";
import styles from "../agregar/AgregarUsuario.module.css";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { ApiContext } from "../../../context/api/ApiProvider";
import { toast } from "react-toastify";

const ModificarUsuario = () => {
  const { updateData, userNombre, userEmail, userTipo, userTelefono } =
    useOutletContext();
  const [nombre, setNombre] = React.useState(userNombre);
  const [email, setEmail] = React.useState(userEmail);
  const [telefono, setTelefono] = React.useState(userTelefono);
  const [tipo, setTipo] = React.useState(userTipo);
  const [password, setPassword] = React.useState("");
  const api = React.useContext(ApiContext);
  const navigate = useNavigate();
  const { userID } = useParams();

  useEffect(() => {
    console.log(userID);
    if (
      !userID ||
      userID == "undefined" ||
      !userNombre ||
      !userEmail ||
      !userTipo
    ) {
      navigate("/inicio/usuarios");
    }
  }, []);

  function handleSubmmit(e) {
    e.preventDefault();
    api.post(
      `/usuarios/${userID}/modificar`,
      { userID, tipo, nombre, email, telefono, password },
      (res) => {
        if (res.data.success) {
          toast.success("Usuario modificado correctamente");
          navigate("/inicio/usuarios");
          updateData();
        }
      }
    );
  }

  return (
    <div className={styles.background}>
      <form
        className={styles.modal + " p-3"}
        onSubmit={(e) => handleSubmmit(e)}
      >
        <h3>Modificar Usuario {userID} </h3>
        <div className={styles.hr}></div>

        <div className={styles.form}>
          <label className="h6">Tipo</label>
          <select
            className="form-control"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
          >
            <option value={0}>Usuario</option>
            <option value={1}>Administrador</option>
          </select>
          <label className="h6 pt-3">Nombre</label>
          <input
            type="text"
            className={"form-control"}
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
          <label className="h6 pt-3">Email</label>
          <input
            type="text"
            className={"form-control"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className="h6 pt-3">Teléfono</label>
          <input
            type="text"
            className={"form-control"}
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
          />
          <label className="h6 pt-3">Contraseña</label>
          <input
            type="text"
            className={"form-control"}
            placeholder="Dejar vacío si no se cambia"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <button className={"btn btn-warning"} type="submit">
            Modificar
          </button>
          <Link to={"/inicio/usuarios"} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ModificarUsuario;
