import React, { useContext, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faClose,
  faHouseCircleCheck,
  faList,
  faPencil,
  faSearch,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { ModalContext } from "../../context/modal/ModalProvider";
import styles from "./Usuarios.module.css";
import { ApiContext } from "../../context/api/ApiProvider";
import { toast } from "react-toastify";

const Usuarios = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [globalFiltering, setGlobalFiltering] = useState("");
  const { showModal, closeModal } = useContext(ModalContext);
  const api = useContext(ApiContext);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({
    Usuario_ID: false,
    Nombre: true,
    Email: true,
    Telefono: true,
    Tipo: true,
  });

  const [userNombre, setUserNombre] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userTelefono, setUserTelefono] = useState("");
  const [userTipo, setUserTipo] = useState(0);
  const [userID, setUserID] = useState(0);

  function updateData() {
    api.get("/usuarios", (res) => {
      setData(res.data);
    });
  }
  function deleteUser(id) {
    api.post("/usuarios/delete", { id }, (res) => {
      if (res.data.success) {
        toast.success("Usuario eliminado correctamente");
        updateData();
      }
    });
  }

  useEffect(() => {
    updateData();
  }, []);

  const columns = [
    {
      header: "ID",
      accessorKey: "Usuario_ID",
    },
    {
      header: "Nombre",
      accessorKey: "Nombre",
    },
    {
      header: "Email",
      accessorKey: "Email",
    },
    {
      header: "Teléfono",
      accessorKey: "Telefono",
    },
    {
      header: "Tipo",
      accessorKey: "Tipo",
    },
    {
      header: "Mensaje",
      accessorKey: "Comentario",
    },
    {
      header: "Modificar",
      cell: (cell) => (
        <div
          key={cell.id}
          className="w-100 d-flex align-items-center justify-content-evenly"
        >
          {cell.row.original.Tipo == "Usuario" ? (
            <Link
              className="btn btn-primary me-1"
              title="Relaciones"
              to={cell.row.original.Usuario_ID + "/editar"}
              onClick={() => {
                setUserNombre(cell.row.original.Nombre);
                setUserEmail(cell.row.original.Email);
                setUserTelefono(cell.row.original.Telefono);
                setUserTipo(cell.row.original.Tipo);
                setUserID(cell.row.original.Usuario_ID);
              }}
            >
              <FontAwesomeIcon icon={faHouseCircleCheck} />
            </Link>
          ) : (
            ""
          )}

          <Link
            className="btn btn-warning me-1"
            title="Modificar"
            to={cell.row.original.Usuario_ID + "/modificar"}
            onClick={() => {
              setUserNombre(cell.row.original.Nombre);
              setUserEmail(cell.row.original.Email);
              setUserTelefono(cell.row.original.Telefono);
              setUserTipo(cell.row.original.Tipo);
              setUserID(cell.row.original.Usuario_ID);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Link>
          <button
            type="button"
            className="btn btn-danger me-1"
            title="Eliminar"
            onClick={() =>
              showModal(
                "Confirmar Acción",
                "¿Esta seguro que desea eliminar este usuario?",
                () => deleteUser(cell.row.original.Usuario_ID)
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      globalFilter: globalFiltering,
      pagination,
      columnVisibility,
    },
  });

  return (
    <div className="col-12">
      <Outlet
        context={{ updateData, userNombre, userEmail, userTelefono, userTipo }}
      />
      <span className="col-12 d-flex flex-row align-items-center p-2 ps-3">
        <Link className={styles.addButton} to={"agregar"}>
          <FontAwesomeIcon icon={faAdd} />
        </Link>
        <div className="vr ms-3 me-3" />
        <FontAwesomeIcon icon={faSearch} />
        <input
          type="text"
          className={"form-control ms-2 " + styles.formContainer}
          style={{ maxWidth: "5cm", minWidth: "3cm" }}
          placeholder="Buscar"
          value={globalFiltering}
          onChange={(e) => setGlobalFiltering(e.target.value)}
        />
      </span>
      <div>
        <table className="table text-center table-striped table-hover h-100">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {data
              ? table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              : "No hay datos para mostrar"}
          </tbody>
        </table>
        {data && data.length > 0 ? (
          <span className="d-flex w-100 justify-content-center align-items-center mb-3">
            <button
              onClick={() => {
                table.previousPage();
              }}
              disabled={!table.getCanPreviousPage()}
              className="btn btn-outline-primary"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <b className="me-2 ms-2">
              Página: {pagination.pageIndex + 1} de {table.getPageCount()}
            </b>
            <button
              onClick={() => {
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
              className="btn btn-outline-primary"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Usuarios;
