import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faHouse,
  faHouseUser,
  faKey,
  faMoneyBill,
  faReceipt,
  faUpload,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../auth/AuthProvider";
import { UsuNuevosContext } from "../../../context/usuNuevos/UsuNuevosProvider";
import axios from "axios";

const Sidebar = () => {
  const [isExpanded, setExpanded] = useState(false);
  const { user, isLogged, token } = useContext(AuthContext);
  const { usunuevos, setUsunuevos, updateUsunuevos } =
    useContext(UsuNuevosContext);

  useEffect(() => {
    if (isLogged && token != null && user.Tipo == 1) {
      updateUsunuevos();
    }
  }, []);

  return (
    <>
      <div
        className={
          "col-md-2 col-4 d-none d-md-flex flex-column g-0 pt-3 " +
          styles.container
        }
      >
        {user && user.Tipo == 1 ? (
          <>
            {" "}
            <NavLink
              to={"usuariosNuevos"}
              className={({ isActive }) =>
                (isActive ? styles.linkActive : "") + " " + styles.link
              }
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={styles.sidebarIcon + " d-none d-lg-block"}
              />
              Usuarios Nuevos
              <div
                className={styles.usuNuevosNumero}
                style={{ opacity: usunuevos == 0 ? "0" : "1" }}
              >
                {usunuevos}
              </div>
            </NavLink>
            <NavLink
              to={"fichas"}
              className={({ isActive }) =>
                (isActive ? styles.linkActive : "") + " " + styles.link
              }
            >
              <FontAwesomeIcon
                icon={faHouse}
                className={styles.sidebarIcon + " d-none d-lg-block"}
              />
              Fichas
            </NavLink>
            <NavLink
              to={"usuarios"}
              className={({ isActive }) =>
                (isActive ? styles.linkActive : "") + " " + styles.link
              }
            >
              <FontAwesomeIcon
                icon={faUser}
                className={styles.sidebarIcon + " d-none d-lg-block"}
              />
              Usuarios
            </NavLink>
            {user.AdmGC == 1 ? (
              <NavLink
                to={"subir"}
                className={({ isActive }) =>
                  (isActive ? styles.linkActive : "") + " " + styles.link
                }
              >
                <FontAwesomeIcon
                  icon={faUpload}
                  className={styles.sidebarIcon + " d-none d-lg-block"}
                />
                Subir Archivos
              </NavLink>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {user && user.Tipo == 0 && user.Rels.P ? (
          <NavLink
            to={"propietario"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faHouseUser}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Propietario
          </NavLink>
        ) : (
          ""
        )}

        {user && user.Tipo == 0 && user.Rels.I ? (
          <NavLink
            to={"inquilino"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faReceipt}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Inquilino
          </NavLink>
        ) : (
          ""
        )}
        {user && user.Tipo == 0 && user.Rels.G && user.AdmGC == 1 ? (
          <NavLink
            to={"gastos"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faMoneyBill}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Gastos Comunes
          </NavLink>
        ) : (
          ""
        )}
        {user && user.Tipo == 0 ? (
          <NavLink
            to={"contra"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faKey}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Cambiar Contraseña
          </NavLink>
        ) : (
          ""
        )}
      </div>

      <div
        className={
          " col-12 d-flex d-md-none flex-row g-0 " + styles.mobileContainer
        }
      >
        {user && user.Tipo == 1 ? (
          <>
            {" "}
            <NavLink
              to={"usuariosNuevos"}
              className={({ isActive }) =>
                (isActive ? styles.linkActive : "") + " " + styles.link
              }
              style={usunuevos != 0 ? { backgroundColor: "#f0d069" } : {}}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={styles.sidebarIcon + " d-none d-lg-block"}
              />
              Usuarios Nuevos
            </NavLink>
            <NavLink
              to={"fichas"}
              className={({ isActive }) =>
                (isActive ? styles.linkActive : "") + " " + styles.link
              }
            >
              <FontAwesomeIcon
                icon={faHouse}
                className={styles.sidebarIcon + " d-none d-lg-block"}
              />
              Fichas
            </NavLink>
            <NavLink
              to={"usuarios"}
              className={({ isActive }) =>
                (isActive ? styles.linkActive : "") + " " + styles.link
              }
            >
              <FontAwesomeIcon
                icon={faUser}
                className={styles.sidebarIcon + " d-none d-lg-block"}
              />
              Usuarios
            </NavLink>
            {user.AdmGC == 1 ? (
              <NavLink
                to={"subir"}
                className={({ isActive }) =>
                  (isActive ? styles.linkActive : "") + " " + styles.link
                }
              >
                <FontAwesomeIcon
                  icon={faUpload}
                  className={styles.sidebarIcon + " d-none d-lg-block"}
                />
                Subir Archivos
              </NavLink>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {user && user.Tipo == 0 && user.Rels.P ? (
          <NavLink
            to={"propietario"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faHouseUser}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Propietario
          </NavLink>
        ) : (
          ""
        )}

        {user && user.Tipo == 0 && user.Rels.I ? (
          <NavLink
            to={"inquilino"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faReceipt}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Inquilino
          </NavLink>
        ) : (
          ""
        )}
        {user && user.Tipo == 0 && user.Rels.G && user.AdmGC == 1 ? (
          <NavLink
            to={"gastos"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faMoneyBill}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Gastos Comunes
          </NavLink>
        ) : (
          ""
        )}
        {user && user.Tipo == 0 ? (
          <NavLink
            to={"contra"}
            className={({ isActive }) =>
              (isActive ? styles.linkActive : "") + " " + styles.link
            }
          >
            <FontAwesomeIcon
              icon={faKey}
              className={styles.sidebarIcon + " d-none d-lg-block"}
            />
            Cambiar Contraseña
          </NavLink>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Sidebar;
