import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../context/api/ApiProvider";

const Inquilinos = () => {
  const api = useContext(ApiContext);
  const [fichas, setFichas] = useState([]);
  const [selectedFicha, setSelectedFicha] = useState("");
  const [fechas, setFechas] = useState([]);
  const [selectedFecha, setSelectedFecha] = useState("");
  const [cuentas, setCuentas] = useState({ pesos: false, dolares: false });

  useEffect(() => {
    updateFichas();
  }, []);

  function updateFichas() {
    api.get("/inquilino/fichas", (res) => {
      setFichas(res.data.fichas);
      if (res.data.fichas) {
        setSelectedFicha(res.data.fichas[0].Codigo);
        setFechas(res.data.fichas[0].fechas);
        setSelectedFecha(res.data.fichas[0].fechas[0].Fecha_ID);
        setCuentas(res.data.fichas[0].cuentas);
      }
    });
  }

  function downloadCuenta(tipo) {
    api.download(
      "/inquilino/" + selectedFicha + "/descargarCuenta/" + tipo,
      (res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "cuenta" + tipo + selectedFicha + ".pdf");
        link.click();
      }
    );
  }

  function downloadRecibo() {
    api.download(
      "/inquilino/" + selectedFicha + "/descargarRecibo/" + selectedFecha,
      (res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "recibo" + selectedFecha + selectedFicha + ".pdf"
        );
        link.click();
      }
    );
  }

  return (
    <div className="col-12 d-flex flex-column align-items-center justify-content-center pt-3">
      <h3 className="text-center">Descargar archivos de Inquilino</h3>
      <hr className="hr w-100" />
      <div className="col-12 col-md-8 col-lg-4 p-1 d-flex flex-column align-items-center">
        {" "}
        <label className="h5 pt-3">Finca</label>
        <select
          className="form-control"
          value={selectedFicha}
          onChange={(e) => {
            setSelectedFicha(e.target.value);
            //cambio cuentas
            setCuentas(
              fichas.filter((ficha) => ficha.Codigo == e.target.value)[0]
                .cuentas
            );

            //cambio fechas
            setFechas(
              fichas.filter((ficha) => ficha.Codigo == e.target.value)[0].fechas
            );
            let fechasFicha = fichas.filter(
              (ficha) => ficha.Codigo == e.target.value
            )[0].fechas;
            if (fechasFicha.length > 0) {
              setSelectedFecha(fechasFicha[0].Fecha_ID);
            } else {
              setSelectedFecha("");
            }
          }}
        >
          {fichas
            ? fichas.map((ficha) => {
                return (
                  <option key={ficha.Propiedad_ID} value={ficha.Codigo}>
                    {ficha.Direccion}
                  </option>
                );
              })
            : ""}
        </select>
        <label className="h5 pt-3">Fecha</label>
        <select
          className="form-control"
          value={selectedFecha}
          onChange={(e) => {
            setSelectedFecha(e.target.value);
          }}
        >
          {fechas
            ? fechas.map((fecha) => {
                return (
                  <option key={fecha.Fecha_ID} value={fecha.Fecha_ID}>
                    {fecha.Fecha}
                  </option>
                );
              })
            : ""}
        </select>
        {fechas.length == 0 ? (
          <p
            className="p text-success mt-1"
            style={{ fontWeight: "bold", fontStyle: "italic" }}
          >
            No hay recibos pendientes
          </p>
        ) : (
          ""
        )}
        <hr className="hr w-100" />
        <button
          className="btn btn-success w-100 mt-3 mb-3"
          disabled={!(selectedFecha && selectedFicha)}
          onClick={downloadRecibo}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Recibo
        </button>
        <hr className="hr w-100" />
        <button
          className="btn btn-success w-100 mt-5"
          disabled={!cuentas.pesos}
          onClick={() => {
            downloadCuenta("pesos");
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Cuenta Pesos
        </button>
        <button
          className="btn btn-success w-100 mt-5"
          disabled={!cuentas.dolares}
          onClick={() => {
            downloadCuenta("dolares");
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Cuenta Dolares
        </button>
      </div>
    </div>
  );
};

export default Inquilinos;
