import React, { useState } from "react";
import styles from "./LoadContext.module.css";

export const LoadContext = React.createContext();

const LoadProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LoadContext.Provider value={{ setIsLoading, isLoading }}>
      {isLoading ? (
        <div
          className={
            styles.loadContainer +
            " vw-100 vh-100 d-flex align-items-center justify-content-center"
          }
        >
          <div className={styles.load}>
            <hr />
            <hr />
            <hr />
            <hr />
          </div>
        </div>
      ) : (
        ""
      )}

      {children}
    </LoadContext.Provider>
  );
};

export default LoadProvider;
