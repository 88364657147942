import React, { useContext, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faClose,
  faHouseCircleCheck,
  faPencil,
  faSearch,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { ModalContext } from "../../context/modal/ModalProvider";
import styles from "./Fichas.module.css";
import { ApiContext } from "../../context/api/ApiProvider";
import { toast } from "react-toastify";
import { LoadContext } from "../../context/load/LoadProvider";
import { AuthContext } from "../auth/AuthProvider";

const Fichas = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [globalFiltering, setGlobalFiltering] = useState("");
  const [filtros, setFiltros] = useState({ P: true, I: true, G: true });
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const { showModal, closeModal } = useContext(ModalContext);
  const api = useContext(ApiContext);
  const [columnVisibility, setColumnVisibility] = useState({
    Propiedad_ID: false,
    Tipo: true,
    Codigo: true,
    Direccion: true,
    TipoLetra: false,
  });
  const { setIsLoading } = useContext(LoadContext);

  //pasar a modificar
  const [ficha, setFicha] = useState(null);
  const [fichaCodigo, setFichaCodigo] = useState(null);
  const [fichaDireccion, setFichaDireccion] = useState(null);
  const [fichaTipo, setFichaTipo] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    filterData();
  }, [data, filtros]);

  function updateData() {
    setIsLoading(true);
    api.get("/fichas", (res) => {
      setData(res.data);
    });
  }
  function filterData() {
    //setIsLoading(true);
    setFilteredData(
      data.filter(
        (dato) =>
          (dato.Tipo && dato.Tipo == "Inquilino" && filtros.I) ||
          (dato.Tipo && dato.Tipo == "Propietario" && filtros.P) ||
          (dato.Tipo && dato.Tipo.startsWith("Gastos Comunes") && filtros.G)
      )
    );
  }
  useEffect(() => {
    setIsLoading(false);
  }, [filteredData]);

  function deleteFicha(id) {
    api.post("/fichas/delete", { id }, (res) => {
      if (res.data.success) {
        toast.success("Ficha eliminada con éxito");
      } else {
        toast.error("Error al eliminar la ficha");
      }
      updateData();
    });
  }

  useEffect(() => {
    updateData();
  }, []);

  const columns = [
    {
      header: "ID",
      accessorKey: "Propiedad_ID",
    },
    { header: "TipoLetra", accessorKey: "TipoLetra" },
    {
      header: "Tipo",
      accessorKey: "Tipo",
    },
    {
      header: "Código",
      accessorKey: "Codigo",
    },
    {
      header: "Dirección",
      accessorKey: "Direccion",
    },
    {
      header: "Modificar",
      cell: (cell) => (
        <div
          key={cell.id}
          className="w-100 d-flex align-items-center justify-content-start"
        >
          <Link
            className="btn btn-primary me-2 "
            title="Relaciones"
            to={cell.row.original.Propiedad_ID + "/editar"}
            onClick={() => {
              setFicha(cell.row.original.Propiedad_ID);
              setFichaCodigo(cell.row.original.Codigo);
              setFichaDireccion(cell.row.original.Direccion);
              setFichaTipo(cell.row.original.TipoLetra);
            }}
          >
            <FontAwesomeIcon icon={faHouseCircleCheck} />
          </Link>
          {cell.row.original.Tipo == "Propietario" ? (
            <Link
              className="btn btn-success me-2"
              title="Subir Archivos"
              to={cell.row.original.Propiedad_ID + "/subir"}
            >
              <FontAwesomeIcon icon={faUpload} />
            </Link>
          ) : (
            ""
          )}

          <Link
            className="btn btn-warning me-2"
            title="Modificar"
            to={cell.row.original.Propiedad_ID + "/modificar"}
            onClick={() => {
              setFicha(cell.row.original.Propiedad_ID);
              setFichaCodigo(cell.row.original.Codigo);
              setFichaDireccion(cell.row.original.Direccion);
              setFichaTipo(cell.row.original.TipoLetra);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Link>
          <button
            type="button"
            className="btn btn-danger me-2"
            title="Eliminar"
            onClick={() =>
              showModal(
                "Confirmar Acción",
                "¿Esta seguro que desea eliminar esta ficha?",
                () => deleteFicha(cell.row.original.Propiedad_ID)
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];
  const table = useReactTable({
    columns,
    data: filteredData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      globalFilter: globalFiltering,
      columnVisibility,
      pagination,
    },
  });

  return (
    <div className="col-12">
      <Outlet
        context={{ ficha, fichaCodigo, fichaDireccion, fichaTipo, updateData }}
      />
      <span className="col-12 d-flex flex-row align-items-center p-2 ps-3">
        <Link className={styles.addButton} to={"agregar"}>
          <FontAwesomeIcon icon={faAdd} />
        </Link>
        <div className="vr ms-3 me-3" />
        <FontAwesomeIcon icon={faSearch} />
        <input
          type="text"
          className={"form-control ms-2 " + styles.formContainer}
          style={{ maxWidth: "5cm", minWidth: "3cm" }}
          placeholder="Buscar"
          value={globalFiltering}
          onChange={(e) => setGlobalFiltering(e.target.value)}
        />
        <div
          className={
            "d-flex flex-row align-items-center form-control justify-content-evenly ms-2 " +
            styles.formContainer
          }
          style={{ width: "4cm" }}
        >
          <h6 className="d-none d-md-block">Propietarios</h6>
          <h6 className="d-block d-md-none">P</h6>
          <input
            type="checkbox"
            className="ms-2"
            onChange={(e) => setFiltros((f) => ({ ...f, P: e.target.checked }))}
            checked={filtros.P}
          />
        </div>
        <div
          className={
            "d-flex flex-row align-items-center form-control justify-content-evenly ms-2 " +
            styles.formContainer
          }
          style={{ width: "4cm" }}
        >
          <h6 className="d-none d-md-block">Inquilinos</h6>
          <h6 className="d-block d-md-none">I</h6>
          <input
            type="checkbox"
            onChange={(e) => setFiltros((f) => ({ ...f, I: e.target.checked }))}
            checked={filtros.I}
            className="ms-2"
          />
        </div>
        {user.AdmGC == 1 ? (
          <div
            className={
              "d-flex flex-row align-items-center form-control justify-content-evenly ms-2 " +
              styles.formContainer
            }
            style={{ width: "5cm" }}
          >
            <h6 className="d-none d-md-block">Gastos Comunes</h6>
            <h6 className="d-block d-md-none">G</h6>
            <input
              type="checkbox"
              className="ms-2"
              onChange={(e) =>
                setFiltros((f) => ({ ...f, G: e.target.checked }))
              }
              checked={filtros.G}
            />
          </div>
        ) : (
          ""
        )}
      </span>
      <div>
        <table className="table text-center table-striped table-hover h-100">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {data
              ? table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              : "No hay datos para mostrar"}
          </tbody>
        </table>
        {data && data.length > 0 ? (
          <span className="d-flex w-100 justify-content-center align-items-center mb-3">
            <button
              onClick={() => {
                table.previousPage();
              }}
              disabled={!table.getCanPreviousPage()}
              className="btn btn-outline-primary"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <b className="me-2 ms-2">
              Página: {pagination.pageIndex + 1} de {table.getPageCount()}
            </b>
            <button
              onClick={() => {
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
              className="btn btn-outline-primary"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Fichas;
