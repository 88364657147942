import React, { useContext, useEffect, useState } from "react";
import styles from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  faUser,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { logout, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    if (user) {
      import("../../../img/administraciones/" + user.AdmLogo)
        .then((logo) => {
          setLogo(logo.default);
        })
        .catch((err) => {
          import("../../../img/administraciones/logoiddpcuad.png").then(
            (logo) => {
              setLogo(logo.default);
            }
          );
        });
    }
  }, [user]);
  return (
    <div
      className={"row g-0 d-flex align-items-center " + styles.mainContainer}
    >
      <div className={"col-4 col-md-2"}>
        <img src={logo} className={styles.logo + " ms-3"} />
      </div>
      <div className={"col-6 col-md-8 pt-2 "}>
        <h3>
          {" "}
          <FontAwesomeIcon icon={faUser} style={{ fontSize: "0.8em" }} />{" "}
          {user ? user.Nombre : ""}
        </h3>
        <p className={"d-none d-md-block"}>{user ? user.Email : ""}</p>
      </div>
      <div className={"col-2 h-100 d-flex "}>
        <button
          className={styles.logoutButton}
          onClick={() => {
            logout()
              .then(() => {
                navigate("/");
              })
              .catch(() => {});
          }}
        >
          <FontAwesomeIcon icon={faSignOut} />
        </button>
      </div>
    </div>
  );
};

export default Header;
