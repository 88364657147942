import React from "react";
import styles from "./Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ title, text, onConfirm, onCancel, closeModal }) => {
  console.log(onConfirm);
  return (
    <div className={styles.background}>
      <div className={styles.modal}>
        <span className={"w-100 d-flex justify-content-end pe-4 pt-3"}>
          <FontAwesomeIcon
            icon={faClose}
            onClick={() => closeModal()}
            style={{ fontSize: "1.5em", cursor: "pointer" }}
          />
        </span>
        <h2>{title}</h2>
        <div className={styles.hr}></div>
        <p className="m-3 " style={{ fontSize: "1.3em" }}>
          {text}
        </p>
        <div className={styles.hr}></div>
        <div className={"d-flex flex-row p-2 justify-content-end"}>
          <button
            className={"btn btn-success me-2"}
            onClick={() => {
              onConfirm();
              closeModal();
            }}
          >
            Confirmar
          </button>
          <button className={"btn btn-danger"} onClick={onCancel}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
