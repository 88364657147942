import React, { useState, useContext, useEffect } from "react";
import styles from "./Login.module.css";
import logoiddp from "../../img/administraciones/logoiddpcuad.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import logoWaven from "../../img/administraciones/logowaven.png";
import logoCq from "../../img/administraciones/logocqsoft.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../auth/AuthProvider";
import { ApiContext } from "../../context/api/ApiProvider";
import loadicon from "../../img/Loading_icon.gif";
import { LoadContext } from "../../context/load/LoadProvider";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { logIn, logInAdm, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isWrong, setIsWrong] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLogged, user, refreshToken } = useContext(AuthContext);
  const [logo, setLogo] = useState(logoiddp);
  const [searchParams, setSearchParams] = useSearchParams();
  const api = useContext(ApiContext);
  const [admID, setAdmID] = useState(null);
  const [admNombre, setAdmNombre] = useState(null);
  const [administraciones, setAdministraciones] = useState(null);
  const [selectedAdm, setSelectedAdm] = useState(null);
  const { setIsLoading } = useContext(LoadContext);

  useEffect(() => {
    setIsLoading(false);
    if (isLogged) {
      navigate("/inicio");
    } else {
      refreshToken()
        .then(() => {
          navigate("/inicio");
        })
        .catch(() => {});
    }

    //logo y administracion
    if (searchParams.get("adm")) {
      setLogo(loadicon);
      api.get(
        "/administraciones/logo?adm=" + searchParams.get("adm"),
        (res) => {
          if (res.data.Logo && res.data.Adm_ID && res.data.Nombre) {
            import("../../img/administraciones/" + res.data.Logo)
              .then((logo) => {
                setLogo(logo.default);
                localStorage.setItem("admLogo", res.data.Logo);
              })
              .catch(() => {
                setLogo(loadicon);
              });
            setAdmNombre(res.data.Nombre);
            localStorage.setItem("admNombre", res.data.Nombre);
            setAdmID(res.data.Adm_ID);
            localStorage.setItem("admID", res.data.Adm_ID);
          } else {
            setLogo(logoiddp);
            localStorage.removeItem("admLogo");
            localStorage.removeItem("admNombre");
            localStorage.removeItem("admID");
          }
        },
        () => {
          setLogo(logoiddp);
        }
      );
    } else if (
      localStorage.getItem("admLogo") &&
      localStorage.getItem("admID") &&
      localStorage.getItem("admNombre")
    ) {
      setLogo(loadicon);
      setAdmNombre(localStorage.getItem("admNombre"));
      setAdmID(localStorage.getItem("admID"));
      import("../../img/administraciones/" + localStorage.getItem("admLogo"))
        .then((logo) => {
          setLogo(logo.default);
        })
        .catch(() => setLogo(logoiddp));
    }
  }, []);

  useEffect(() => {
    if (isLogged) {
      navigate("/inicio");
    }
  }, []);

  function handleSubmit(e) {
    setLoading(true);
    setIsWrong(false);
    e.preventDefault();
    if (administraciones) {
      logInAdm(email, password, selectedAdm)
        .then(() => {
          setLoading(false);
          navigate("/inicio");
        })
        .catch((reason) => {
          setLoading(false);
          if (reason == "Contraseña incorrecta") {
            setIsWrong(true);
          }
        });
    } else {
      logIn(email, password)
        .then(() => {
          setLoading(false);
          navigate("/inicio");
        })
        .catch((reason) => {
          console.log(reason);
          if (reason.multiple) {
            //multiple
            setLoading(false);
            if (
              localStorage.getItem("admID") &&
              reason.administraciones.filter(
                (adm) => adm.Adm_ID == localStorage.getItem("admID")
              ).length > 0
            ) {
              //la administracion esta en local
              logInAdm(email, password, localStorage.getItem("admID"))
                .then(() => {
                  setLoading(false);
                  navigate("/inicio");
                })
                .catch((reason) => {
                  setLoading(false);
                  if (reason == "Contraseña incorrecta") {
                    setIsWrong(true);
                  }
                });
            } else {
              setAdministraciones(reason.administraciones);
              setSelectedAdm(reason.administraciones[0].Adm_ID);
            }
          } else {
            setLoading(false);
            if (reason == "Contraseña incorrecta") {
              setIsWrong(true);
            }
          }
        });
    }
  }
  return (
    <div className="align-self-start col-12 d-flex align-items-center flex-column">
      <form
        className={
          "p-3 pt-5 pb-4 d-flex flex-column align-items-center col-12 col-md-8 col-lg-3 " +
          styles.loginContainer
        }
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <img src={logo} style={{ width: "50%", maxWidth: "5cm" }} />
        <hr className="hr w-75"></hr>
        <h2>Iniciar Sesión</h2>
        <hr className="hr w-75"></hr>
        <h6>Email</h6>
        <div className="d-flex flex-row align-items-center w-75 mb-3">
          <FontAwesomeIcon
            icon={faEnvelope}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <input
            className="form-control"
            type={"text"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
          />
        </div>
        {administraciones ? (
          <>
            {" "}
            <h6>Administración</h6>
            <p className="text-center">
              Usted está asociado a más de una administración, por favor,
              seleccione a cual quiere ingresar e introduzca la contraseña
              correspondiente.
            </p>
            <div className="d-flex flex-row align-items-center w-75 mb-5">
              <FontAwesomeIcon
                icon={faBuilding}
                className="me-3"
                style={{ fontSize: "1.3em" }}
              />
              <select
                className="form-control"
                value={selectedAdm}
                onChange={(e) => {
                  setSelectedAdm(e.target.value);
                }}
              >
                {administraciones.map((adm) => (
                  <option key={adm.Adm_ID} value={adm.Adm_ID}>
                    {adm.Nombre}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          ""
        )}

        <h6>Contraseña</h6>
        <div className="d-flex flex-row align-items-center w-75 ">
          <FontAwesomeIcon
            icon={faKey}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <input
            className="form-control"
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Contraseña"
          />
        </div>
        <h6
          className="text-danger mt-3"
          style={{ opacity: isWrong ? "1" : "0" }}
        >
          Usuario o Contraseña Incorrectos
        </h6>
        <hr className="hr w-75"></hr>
        <button
          className="btn btn-primary w-75 mb-3"
          type="submit"
          disabled={loading}
        >
          Iniciar Sesión
        </button>
        <Link
          to="register"
          className="btn btn-outline-primary w-75"
          disabled={loading}
        >
          Registrarse
        </Link>
      </form>
      {localStorage.getItem("admID") ? (
        <button
          className="btn btn-outline-secondary m-3"
          onClick={() => {
            setLogo(logoiddp);
            localStorage.removeItem("admID");
            localStorage.removeItem("admNombre");
            localStorage.removeItem("admLogo");
            setAdmID(null);
            setAdmNombre(null);
          }}
        >
          Olvidar Administración
        </button>
      ) : (
        ""
      )}

      <div
        className={
          "col-10 col-md-7 col-lg-2 mt-3 mb-3 p-3 row " + styles.branding
        }
      >
        <div
          className="col-6 d-flex p-3 align-items-center justify-content-center"
          style={{ cursor: "pointer" }}
          onClick={() => (window.location = "http://wavensolutions.com")}
        >
          <img className={"w-75"} src={logoWaven} />
        </div>
        <div
          className="col-6 d-flex p-3 align-items-center justify-content-center"
          style={{
            borderLeft: "1px solid rgba(0, 0, 0, 0.144)",
            cursor: "pointer",
          }}
          onClick={() => (window.location = "http://cqsoft.com.uy")}
        >
          <img className={"w-75"} src={logoCq} />
        </div>
      </div>
    </div>
  );
};

export default Login;
