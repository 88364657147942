import React, { useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalContext } from "../../context/modal/ModalProvider";

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [isLogged, setIsLogged] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const { showModal } = useContext(ModalContext);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [token]);

  function logout() {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/logout", null, {
          withCredentials: true,
        })
        .then((res) => {
          setIsLogged(false);
          setUser(null);
          setToken(null);
          resolve(true);
        })
        .catch((err) => {
          setIsLogged(false);
          setUser(null);
          setToken(null);
          toast.error(
            "El servicio no está disponible actualmente, reintente más tarde"
          );
          reject("Error");
        });
    });
  }

  function refreshToken() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/refreshToken", {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.token) {
            setToken(res.data.token);
            setIsLogged(true);
            setUser(res.data.user);

            resolve(true);
          } else {
            setIsLogged(false);
            setUser(null);
            setToken(null);
            reject("Refresh failed");
          }
        })
        .catch((err) => {
          setIsLogged(false);
          setUser(null);
          setToken(null);
          reject("Refresh failed");
        });
    });
  }

  function logIn(email, password) {
    let data = { email, password };
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/login", data, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.token) {
            setToken(res.data.token);
            setIsLogged(true);
            setUser(res.data.user);
            resolve(true);
          } else if (
            res.data.error &&
            res.data.error == "Usuario no verificado"
          ) {
            setIsLogged(false);
            setUser(null);
            setToken(null);
            showModal(
              "Aún no verificado",
              "Su usuario aún no ha sido verificado, por favor pongase en contacto con la administración"
            );
            reject("No verificado");
          } else if (res.data.error && res.data.error == "multiple") {
            setIsLogged(false);
            setUser(null);
            setToken(null);
            reject({
              multiple: true,
              administraciones: res.data.administraciones,
            });
          } else {
            setIsLogged(false);
            setUser(null);
            setToken(null);
            reject("Contraseña incorrecta");
          }
        })
        .catch((err) => {
          toast.error(
            "Actualmente el servicio no está disponible, reintente más tarde"
          );
          reject("Error");
        });
    });
  }

  function register(data) {
    return axios.post(process.env.REACT_APP_API_URL + "/register", data);
  }

  function logInAdm(email, password, adm) {
    let data = { email, password, adm };
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/loginAdm", data, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.token) {
            setToken(res.data.token);
            setIsLogged(true);
            setUser(res.data.user);
            resolve(true);
          } else if (
            res.data.error &&
            res.data.error == "Usuario no verificado"
          ) {
            setIsLogged(false);
            setUser(null);
            setToken(null);
            showModal(
              "Aún no verificado",
              "Su usuario aún no ha sido verificado, por favor pongase en contacto con la administración"
            );
            reject("No verificado");
          } else {
            setIsLogged(false);
            setUser(null);
            setToken(null);
            reject("Contraseña incorrecta");
          }
        })
        .catch((err) => {
          toast.error(
            "Actualmente el servicio no está disponible, reintente más tarde"
          );
          reject("Error");
        });
    });
  }
  return (
    <AuthContext.Provider
      value={{
        isLogged,
        user,
        logIn,
        logout,
        logInAdm,
        register,
        refreshToken,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
