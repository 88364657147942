import React, { useContext } from "react";
import styles from "./AgregarFicha.module.css";
import {
  Link,
  useNavigate,
  useOutlet,
  useOutletContext,
} from "react-router-dom";
import { ApiContext } from "../../../context/api/ApiProvider";
import { toast } from "react-toastify";
import { ModalContext } from "../../../context/modal/ModalProvider";
import { AuthContext } from "../../auth/AuthProvider";

const AgregarFicha = () => {
  const [tipo, setTipo] = React.useState("I");
  const [codigo, setCodigo] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const api = React.useContext(ApiContext);
  const navigate = useNavigate();
  const { showModal } = React.useContext(ModalContext);
  const { updateData } = useOutletContext();
  const { user } = useContext(AuthContext);

  const handleSubmit = (e) => {
    if (e.preventDefault) e.preventDefault();
    api.post("/fichas/agregar", { tipo, codigo, direccion }, (res) => {
      if (res.data.success) {
        navigate("/inicio/fichas");
        toast.success("Ficha agregada correctamente");
        updateData();
      } else if (res.data.error && res.data.error == "Ficha ya existe") {
        showModal(
          "Error agregando la ficha",
          "Ya existe una ficha con ese código y tipo"
        );
      } else {
        toast.error("Error al agregar la ficha");
        navigate("/inicio/fichas");
      }
    });
  };
  return (
    <div className={styles.background}>
      <form className={styles.modal + " p-3"} onSubmit={(e) => handleSubmit(e)}>
        <h3>Agregar Ficha</h3>
        <div className={styles.hr}></div>

        <div className={styles.form}>
          <label className="h6">Tipo</label>
          <select
            className="form-control"
            value={tipo}
            onChange={(e) => {
              setTipo(e.target.value);
            }}
            required
          >
            <option value="I">Inquilino</option>
            <option value={"P"}>Propietario</option>
            {user.AdmGC == 1 ? (
              <>
                <option value="G">Gastos Comunes</option>
                <option value="H">Gastos Comunes Inq.</option>
                <option value="J">Gastos Comunes Prop.</option>
              </>
            ) : (
              ""
            )}
          </select>
          <label className="h6 pt-3">Código</label>
          <input
            type="text"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
            required
            className={"form-control"}
          />
          <label className="h6 pt-3">Dirección</label>
          <input
            type="text"
            value={direccion}
            onChange={(e) => {
              setDireccion(e.target.value);
            }}
            required
            className={"form-control"}
          />
        </div>
        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <button type="submit" className={"btn btn-success"}>
            Agregar
          </button>
          <Link to={"/inicio/fichas"} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default AgregarFicha;
