import React, { createContext, useState } from "react";
import Modal from "./Modal";

export const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [isVisible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [onConfirm, setConfirm] = useState(null);
  const [onCancel, setCancel] = useState(null);
  function closeModal() {
    setVisible(false);
  }
  function showModal(
    title,
    text,
    onConfirm = () => closeModal(),
    onCancel = () => closeModal()
  ) {
    setTitle(title);
    setText(text);
    setCancel(() => onCancel);
    setConfirm(() => onConfirm);
    setVisible(true);
  }

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {isVisible ? (
        <Modal
          title={title}
          text={text}
          onCancel={onCancel}
          onConfirm={onConfirm}
          closeModal={closeModal}
        />
      ) : (
        ""
      )}

      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
