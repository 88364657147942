import React, { useContext, useEffect, useState } from "react";
import styles from "./Register.module.css";
import logoiddp from "../../img/administraciones/logoiddpcuad.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBank,
  faEnvelope,
  faEnvelopeCircleCheck,
  faExclamationTriangle,
  faKey,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import logoWaven from "../../img/administraciones/logowaven.png";
import logoCq from "../../img/administraciones/logocqsoft.png";
import loadicon from "../../img/Loading_icon.gif";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";
import { ApiContext } from "../../context/api/ApiProvider";
import { toast } from "react-toastify";
import { ModalContext } from "../../context/modal/ModalProvider";
const Register = () => {
  const navigate = useNavigate();
  const [isWrong, setIsWrong] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLogged, user, refreshToken, register } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const [logo, setLogo] = useState(logoiddp);
  const [searchParams, setSearchParams] = useSearchParams();
  const api = useContext(ApiContext);
  const [admID, setAdmID] = useState(null);
  const [admNombre, setAdmNombre] = useState(null);
  const [administraciones, setAdministraciones] = useState([]);
  const [selectedAdm, setSelectedAdm] = useState(null);
  const [localAdm, setLocalAdm] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [aclaracion, setAclaracion] = useState("");
  const [password2, setPassword2] = useState("");

  useEffect(() => {
    //obtener administraciones
    api.get("/administraciones", (res) => {
      if (res.data.administraciones) {
        setAdministraciones(res.data.administraciones);
        if (localStorage.getItem("admID")) {
          setSelectedAdm(localStorage.getItem("admID"));
          setLocalAdm(true);
        } else {
          setSelectedAdm(res.data.administraciones[0].Adm_ID);
        }
      }
    });
    //logo y administracion
    if (searchParams.get("adm")) {
      setLogo(loadicon);
      api.get(
        "/administraciones/logo?adm=" + searchParams.get("adm"),
        (res) => {
          if (res.data.Logo && res.data.Adm_ID && res.data.Nombre) {
            import("../../img/administraciones/" + res.data.Logo)
              .then((logo) => {
                setLogo(logo.default);
                localStorage.setItem("admLogo", res.data.Logo);
              })
              .catch(() => {
                setLogo(loadicon);
              });
            setAdmNombre(res.data.Nombre);
            localStorage.setItem("admNombre", res.data.Nombre);
            setAdmID(res.data.Adm_ID);
            localStorage.setItem("admID", res.data.Adm_ID);
          } else {
            setLogo(logoiddp);
            localStorage.removeItem("admLogo");
            localStorage.removeItem("admNombre");
            localStorage.removeItem("admID");
          }
        },
        () => {
          setLogo(logoiddp);
        }
      );
    } else if (
      localStorage.getItem("admLogo") &&
      localStorage.getItem("admID") &&
      localStorage.getItem("admNombre")
    ) {
      setLogo(loadicon);
      setAdmNombre(localStorage.getItem("admNombre"));
      setAdmID(localStorage.getItem("admID"));
      import("../../img/administraciones/" + localStorage.getItem("admLogo"))
        .then((logo) => {
          setLogo(logo.default);
        })
        .catch(() => setLogo(logoiddp));
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (password !== password2) {
      setIsWrong(true);
      toast.error("Las contraseñas no coinciden");
      setLoading(false);
      return;
    } else {
      register({
        email,
        password,
        nombre,
        telefono,
        aclaracion,
        adm: selectedAdm,
      })
        .then((res) => {
          if (res.data.error) {
            showModal("Error", res.data.error);
          } else {
            navigate("/");
            showModal(
              "Registro Exitoso",
              "Su solicitud de registro ha sido enviada, en breve recibirá un email con la confirmación por parte de la administración"
            );
          }
        })
        .catch((err) => {
          toast.error("Ocurrió un error al registrarse, reintente más tarde");
          console.log(err);
        });
    }
  }
  return (
    <div className="align-self-start col-12 d-flex align-items-center flex-column">
      <form
        className={
          "p-3 pt-5 pb-4 d-flex flex-column align-items-center col-12 col-md-8 col-lg-3 " +
          styles.loginContainer
        }
        onSubmit={(e) => handleSubmit(e)}
      >
        <img src={logo} style={{ width: "50%", maxWidth: "5cm" }} />
        <hr className="hr w-75"></hr>
        <h2>Registrarse</h2>
        <h6>Pedir Autorizacion</h6>
        <hr className="hr w-75"></hr>
        <h6>Nombre Completo</h6>
        <div className="d-flex flex-row align-items-center w-75 mb-3">
          <FontAwesomeIcon
            icon={faUser}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <input
            className="form-control"
            type={"text"}
            placeholder="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </div>
        <h6>Email</h6>
        <div className="d-flex flex-row align-items-center w-75 mb-3">
          <FontAwesomeIcon
            icon={faEnvelope}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <input
            className="form-control"
            type={"email"}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <h6>Teléfono</h6>
        <div className="d-flex flex-row align-items-center w-75 mb-3">
          <FontAwesomeIcon
            icon={faPhone}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <input
            className="form-control"
            type={"phone"}
            placeholder="Teléfono"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            required
          />
        </div>
        <h6>Administración</h6>
        <div className="d-flex flex-row align-items-center w-75 mb-3">
          <FontAwesomeIcon
            icon={faBank}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <select
            className="form-control"
            value={selectedAdm}
            onChange={(e) => {
              setSelectedAdm(e.target.value);
            }}
            disabled={localAdm}
            required
          >
            {administraciones.map((adm) => (
              <option key={adm.Adm_ID} value={adm.Adm_ID}>
                {adm.Nombre}
              </option>
            ))}
          </select>
        </div>
        <h6>Aclaración</h6>
        <p className="text-dark" style={{ fontWeight: "bold" }}>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="me-3 text-danger "
          />
          Aclare su relación con la administración
        </p>
        <div className="d-flex flex-row align-items-center w-75 mb-3">
          <FontAwesomeIcon
            icon={faEnvelopeCircleCheck}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <textarea
            className="form-control"
            type={"number"}
            placeholder="Aclaración"
            value={aclaracion}
            onChange={(e) => setAclaracion(e.target.value)}
            minLength={8}
            required
          ></textarea>
        </div>
        <h6>Contraseña</h6>
        <div className="d-flex flex-row align-items-center w-75 mb-3">
          <FontAwesomeIcon
            icon={faKey}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <input
            className="form-control"
            type={"password"}
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            minLength={3}
            required
          />
        </div>
        <h6>Repetir Contraseña</h6>
        <div className="d-flex flex-row align-items-center w-75 ">
          <FontAwesomeIcon
            icon={faKey}
            className="me-3"
            style={{ fontSize: "1.3em" }}
          />
          <input
            className="form-control"
            type={"password"}
            placeholder="Repetir Contraseña"
            onChange={(e) => setPassword2(e.target.value)}
            value={password2}
            minLength={3}
            required
          />
        </div>
        <hr className="hr w-75"></hr>
        <button
          type="submit"
          disabled={loading}
          className="btn btn-primary w-75 mb-3"
        >
          Registrarse
        </button>
        <Link to={"/"} className="btn btn-outline-primary w-75">
          Iniciar Sesión
        </Link>
      </form>
      <div
        className={
          "col-10 col-md-7 col-lg-2 mt-5 p-3 row mb-3 " + styles.branding
        }
      >
        <div
          className="col-6 d-flex p-3 align-items-center justify-content-center"
          style={{ cursor: "pointer" }}
          onClick={() => (window.location = "http://wavensolutions.com")}
        >
          <img className={"w-75"} src={logoWaven} />
        </div>
        <div
          className="col-6 d-flex p-3 align-items-center justify-content-center"
          style={{
            borderLeft: "1px solid rgba(0, 0, 0, 0.144)",
            cursor: "pointer",
          }}
          onClick={() => (window.location = "http://cqsoft.com.uy")}
        >
          <img className={"w-75"} src={logoCq} />
        </div>
      </div>
    </div>
  );
};

export default Register;
