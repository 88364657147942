import React, { useContext, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ApiContext } from "../../context/api/ApiProvider";
import { toast } from "react-toastify";
import { LoadContext } from "../../context/load/LoadProvider";
import { UsuNuevosContext } from "../../context/usuNuevos/UsuNuevosProvider";

const UsuariosNuevos = () => {
  const [data, setData] = useState([]);
  const api = useContext(ApiContext);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const columnVisibility = {
    Usuario_ID: false,
    Nombre: true,
    Telefono: true,
    Email: true,
    Mensaje: true,
  };
  const { setIsLoading } = useContext(LoadContext);
  const { updateUsunuevos } = useContext(UsuNuevosContext);

  useEffect(() => {
    updatedata();
  }, []);

  //funciones
  function updatedata() {
    api.get("/usuariosNuevos", (res) => {
      setData(res.data);
    });
  }

  function handleVerify(id) {
    api.post("/usuariosNuevos/verify", { id }, (res) => {
      updatedata();
      toast.success("Usuario verificado");
      updateUsunuevos();
    });
  }

  function handleReject(id) {
    api.post("/usuariosNuevos/reject", { id }, (res) => {
      updatedata();
      toast.success("Usuario rechazado");
      updateUsunuevos();
    });
  }

  //tabla
  const columns = [
    {
      header: "ID",
      accessorKey: "Usuario_ID",
    },
    {
      header: "Nombre",
      accessorKey: "Nombre",
    },
    {
      header: "Teléfono",
      accessorKey: "Telefono",
    },
    {
      header: "Email",
      accessorKey: "Email",
    },
    {
      header: "Mensaje",
      accessorKey: "Mensaje",
    },
    {
      header: "Verificar",
      cell: (cell) => (
        <div
          key={cell.id}
          className="w-100 d-flex align-items-center justify-content-evenly"
        >
          <button
            type="button"
            className="btn btn-success me-1"
            title="Verificar"
            onClick={() => {
              handleVerify(cell.row.original.Usuario_ID);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleReject(cell.row.original.Usuario_ID)}
            title="Rechazar"
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
      ),
    },
  ];
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
      columnVisibility,
    },
  });

  return (
    <div className="col-12">
      <table className="table text-center table-striped table-hover">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {data
            ? table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            : "No hay datos para mostrar"}
        </tbody>
      </table>
      {data && data.length > 0 ? (
        <span className="d-flex w-100 justify-content-center align-items-center">
          <button
            onClick={table.previousPage}
            disabled={!table.getCanPreviousPage()}
            className="btn btn-outline-primary"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <b className="me-2 ms-2">
            Página: {pagination.pageIndex + 1} de {table.getPageCount()}
          </b>
          <button
            onClick={table.nextPage}
            disabled={!table.getCanNextPage()}
            className="btn btn-outline-primary"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default UsuariosNuevos;
