import axios from "axios";
import React, { useContext } from "react";
import { AuthContext } from "../../components/auth/AuthProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoadContext } from "../load/LoadProvider";

export const ApiContext = React.createContext();

const ApiProvider = ({ children }) => {
  const { logout, refreshToken, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoadContext);

  function get(
    endpoint,
    then = () => {},
    fail = (err) => {
      if (err.response) {
        console.log(err.response);
        if (
          err.response.status === 401 &&
          (err.response.data.error === "Token Expirado" ||
            err.response.data.error === "No hay sesion iniciada")
        ) {
          console.log("token expired");
          refreshToken()
            .then(() => {
              get(endpoint, then, fail, autoLoad);
            })
            .catch(() => {
              toast.error("sesión expirada, vuelva a iniciar sesión");
              logout();
              navigate("/");
            });
        } else {
          //toast.error("ocurrió un error en el servidor, reintente más tarde");
        }
      } else {
        toast.error(
          "actualmente no se puede acceder al servicio, reintente más tarde"
        );
      }
    },
    autoLoad = true
  ) {
    if (autoLoad) setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + endpoint, {
        withCredentials: true,
      })
      .then(then)
      .then(() => {
        if (autoLoad) {
          setIsLoading(false);
        }
      })
      .catch(fail)
      .catch(() => {
        if (autoLoad) {
          setIsLoading(false);
        }
      });
  }

  function post(
    endpoint,
    data,
    then = () => {},
    fail = (err) => {
      if (err.response) {
        if (
          err.response.status === 401 &&
          (err.response.data.error === "Token Expirado" ||
            err.response.data.error === "No hay sesion iniciada")
        ) {
          refreshToken()
            .then(() => {
              post(endpoint, data, then, fail, autoLoad);
            })
            .catch(() => {
              toast.error("sesión expirada, vuelva a iniciar sesión");
              logout();
              navigate("/");
            });
        } else {
          console.log(err.response.status);
          toast.error("ocurrió un error en el servidor, reintente más tarde");
        }
      } else {
        toast.error(
          "actualmente no se puede acceder al servicio, reintente más tarde"
        );
        console.log(err.request);
      }
    },
    autoLoad = true
  ) {
    if (autoLoad) {
      setIsLoading(true);
    }
    axios
      .post(process.env.REACT_APP_API_URL + endpoint, data, {
        withCredentials: true,
      })
      .then(then)
      .then(() => {
        if (autoLoad) {
          setIsLoading(false);
        }
      })
      .catch(fail)
      .catch(() => {
        if (autoLoad) {
          setIsLoading(false);
        }
      });
  }

  function download(
    endpoint,
    then = () => {},
    fail = (err) => {
      if (err.response) {
        console.log(err.response);
        if (err.response.status === 401) {
          console.log("token expired");
          refreshToken()
            .then(() => {
              download(endpoint, then, fail);
            })
            .catch(() => {
              toast.error("sesión expirada, vuelva a iniciar sesión");
              logout();
              navigate("/");
            });
        } else {
          toast.error("ocurrió un error en el servidor, reintente más tarde");
        }
      } else {
        toast.error(
          "actualmente no se puede acceder al servicio, reintente más tarde"
        );
      }
    }
  ) {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + endpoint, {
        withCredentials: true,
        responseType: "blob",
      })
      .then(then)
      .then(() => setIsLoading(false))
      .catch(fail)
      .catch(() => setIsLoading(false));
  }

  return (
    <ApiContext.Provider value={{ get, post, download }}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
