import React, { useContext, useEffect, useLayoutEffect } from "react";
import Header from "./header/Header";
import styles from "./Inicio.module.css";
import Sidebar from "./sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";
import axios from "axios";
import { UsuNuevosContext } from "../../context/usuNuevos/UsuNuevosProvider";

const Inicio = () => {
  const { isLogged, user, logIn, logout, refreshToken, token } =
    useContext(AuthContext);

  const { usunuevos, setUsunuevos, updateUsunuevos } =
    useContext(UsuNuevosContext);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (!isLogged) {
      refreshToken()
        .then(() => {})
        .catch(() => {
          navigate("/");
        });
    }
    //axios.get(process.env.REACT_APP_API_URL + "/test") PRUEBA
  }, []);

  return (
    <>
      {isLogged ? (
        <div className={styles.mainContainer}>
          <Header />
          <div
            className={
              "row d-flex flex-md-row flex-column g-0 " + styles.mainView
            }
          >
            <Sidebar />
            <div className={"col-12 col-md-10 " + styles.outletContainer}>
              {isLogged ? <Outlet /> : <></>}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Inicio;
