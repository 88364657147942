import React, { useContext, useState } from "react";
import styles from "./fichas/agregar/AgregarFicha.module.css";
import { Link, useNavigate } from "react-router-dom";
import { ApiContext } from "../context/api/ApiProvider";
import { toast } from "react-toastify";

const CambiarContra = () => {
  const api = useContext(ApiContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      toast.error("Las contraseñas no coinciden");
      return;
    }
    api.post("/cambiarContra", { password }, (res) => {
      if (res.data.success) {
        toast.success("Contraseña cambiada con éxito");
        navigate(-1);
      } else {
        toast.error("Error al cambiar la contraseña");
      }
    });
  };

  return (
    <div className={styles.background}>
      <form className={styles.modal + " p-3"} onSubmit={handleSubmit}>
        <h3>Cambiar Contraseña</h3>
        <div className={styles.hr + " mb-2"}></div>
        <h6>Contraseña Nueva</h6>
        <input
          type="password"
          className="form-control mt-3 mb-1"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          minLength={3}
        />
        <h6>Repetir Contraseña</h6>
        <input
          type="password"
          className="form-control mt-3"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          minLength={3}
        />
        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <button className={"btn btn-success"}>Cambiar</button>
          <Link to={-1} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default CambiarContra;
