import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./UserStyle.module.css";
import React, { useContext, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ApiContext } from "../context/api/ApiProvider";
import { AuthContext } from "./auth/AuthProvider";
import { useNavigate } from "react-router-dom";

const GastosComunes = () => {
  const [archivosExtra, setArchivosExtra] = useState([]);
  const [fichas, setFichas] = useState([]);
  const [selectedFicha, setSelectedFicha] = useState("");
  const [archivos, setArchivos] = useState({
    liquidacion: false,
    recibo: false,
    reciboInquilino: false,
    reciboPropietario: false,
  });
  const api = useContext(ApiContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.AdmGC !== 1) {
      navigate("/inicio");
    } else {
      updateFichas();
    }
  }, []);

  const updateFichas = () => {
    api.get("/gastoscomunes/fichas", (res) => {
      if (res.data.fichas) {
        setFichas(res.data.fichas);
        setSelectedFicha(res.data.fichas[0].Codigo);
        setArchivosExtra(res.data.fichas[0].archivosExtra);
        setArchivos(res.data.fichas[0].archivos);
      }
    });
  };

  function downloadLiquidacion() {
    api.download(
      "/gastoscomunes/" + selectedFicha + "/descargar/liquidacion",
      (res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "liquidación" + selectedFicha + ".pdf");
        link.click();
      }
    );
  }

  function downloadRecibo(tipo) {
    api.download(
      "/gastoscomunes/" + selectedFicha + "/descargar/recibo/" + tipo,
      (res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "recibo" + selectedFicha.slice(0, -1) + tipo + ".pdf"
        );
        link.click();
      }
    );
  }

  function downloadArchivoExtra(nombre) {
    api.download(
      "/gastoscomunes/" + selectedFicha + "/descargar/archivo/" + nombre,
      (res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre);
        link.click();
      }
    );
  }

  const columns = [
    {
      header: "Nombre",
      accessorKey: "Nombre",
    },
    {
      header: "Descargar",
      cell: (cell) => (
        <div className="d-flex align-items-center justify-content-start">
          <button
            className="btn btn-success"
            onClick={() => downloadArchivoExtra(cell.row.original.Nombre)}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      ),
    },
  ];
  const table = useReactTable({
    data: archivosExtra,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div className="col-12 d-flex flex-column align-items-center justify-content-start pt-3 h-100">
      <h3 className="text-center">Descargar archivos de Gastos Comunes</h3>
      <hr className="hr w-100" />
      <div className="col-12 col-md-8 col-lg-4 p-1 d-flex flex-column align-items-center pb-5">
        {" "}
        <label className="h5 pt-3">Cuenta</label>
        <select
          className="form-control"
          value={selectedFicha}
          onChange={(e) => {
            setSelectedFicha(e.target.value);
            setArchivos(
              fichas.filter((ficha) => ficha.Codigo == e.target.value)[0]
                .archivos
            );
            setArchivosExtra(
              fichas.filter((ficha) => ficha.Codigo == e.target.value)[0]
                .archivosExtra
            );
          }}
        >
          {fichas
            ? fichas.map((ficha) => {
                return (
                  <option key={ficha.Codigo} value={ficha.Codigo}>
                    {ficha.Direccion}
                  </option>
                );
              })
            : ""}
        </select>
        <hr className="hr w-100" />
        <button
          className="btn btn-success w-100 mt-5"
          disabled={!archivos.liquidacion}
          onClick={downloadLiquidacion}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Liquidación
        </button>
        {archivos.recibo ? (
          <button
            className="btn btn-success w-100 mt-5"
            disabled={!archivos.recibo}
            onClick={() => downloadRecibo("A")}
          >
            <FontAwesomeIcon icon={faDownload} /> Descargar Recibo
          </button>
        ) : (
          ""
        )}
        {archivos.reciboInquilino ? (
          <button
            className="btn btn-success w-100 mt-5"
            disabled={!archivos.reciboInquilino}
            onClick={() => downloadRecibo("I")}
          >
            <FontAwesomeIcon icon={faDownload} /> Descargar Recibo de Inquilino
          </button>
        ) : (
          ""
        )}
        {archivos.reciboPropietario ? (
          <button
            className="btn btn-success w-100 mt-5"
            disabled={!archivos.reciboPropietario}
            onClick={() => downloadRecibo("P")}
          >
            <FontAwesomeIcon icon={faDownload} /> Descargar Recibo de
            Propietario
          </button>
        ) : (
          ""
        )}
        <hr className="hr w-100" />
        <h4 className="mb-3">Archivos Extra</h4>
        <div className={"w-100 " + styles.extraTable}>
          <table className="w-100 table table-striped">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {archivosExtra
                ? table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                : "No hay archivos para mostrar"}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GastosComunes;
