import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./SubirArchivo.module.css";
import { Link, useNavigate } from "react-router-dom";
import { ApiContext } from "../../context/api/ApiProvider";
import { toast } from "react-toastify";
import { AuthContext } from "../auth/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

const SubirArchivo = () => {
  const [data, setData] = useState([]);
  const api = useContext(ApiContext);
  const [edificio, setEdificio] = useState("");
  const fileInput = useRef(null);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [archivos, setArchivos] = useState(null);

  useEffect(() => {
    if (user.AdmGC !== 1) {
      navigate("/inicio");
    } else {
      updateData();
    }
  }, []);
  useEffect(() => {
    if (edificio) {
      getArchivos();
    }
  }, [edificio]);

  function updateData() {
    api.get("/gastos/edificios", (res) => {
      if (res.data.error) {
        toast.error(res.data.error);
        return;
      }
      setData(res.data);
      if (res.data.length > 0) {
        setEdificio(res.data[0]);
      }
    });
  }
  function getArchivos() {
    api.get("/gastos/" + edificio + "/archivos", (res) => {
      if (res.data.success) {
        setArchivos(res.data.archivos);
      } else {
        toast.error("Error obteniendo archivos");
      }
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("edificio", edificio);
    for (let i = 0; i < fileInput.current.files.length; i++) {
      formData.append("archivo" + i, fileInput.current.files[i]);
    }
    api.post("/gastos/subir", formData, (res) => {
      if (res.data.success) {
        toast.success("Archivos subidos correctamente");
        fileInput.current.value = null;
        navigate(-1);
      } else {
        toast.error("Error subiendo archivos");
      }
      updateData();
    });
  }
  function handleDelete(file) {
    api.post("/gastos/delete", { edificio, file }, (res) => {
      if (res.data.success) {
        toast.success("Archivo eliminado correctamente");
      } else {
        toast.error("Error eliminando archivo");
      }
      getArchivos();
    });
  }

  const columns = [
    { header: "Nombre", accessorKey: "Nombre" },
    {
      header: "Modificar",
      cell: (cell) => {
        return (
          <>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => handleDelete(cell.row.original.Nombre)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        );
      },
    },
  ];
  const table = useReactTable({
    data: archivos,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div className={styles.background}>
      <form className={styles.modal + " p-3"} onSubmit={(e) => handleSubmit(e)}>
        <h3>Archivo de GC</h3>
        <div className={styles.hr}></div>

        <div className={styles.form}>
          <label className="h6">Edificio</label>
          <select
            className="form-control mb-3"
            value={edificio}
            onChange={(e) => setEdificio(e.target.value)}
          >
            {data
              ? data.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))
              : ""}
          </select>

          {archivos ? (
            <>
              <label className="h6">Archivos Subidos</label>
              <div style={{ maxHeight: "25vh", overflowY: "auto" }}>
                <table className="table text-center table-striped table-hover">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {archivos ? (
                      table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <div className="w-100">No hay archivos subidos</div>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            ""
          )}

          <label className="h6">Subir nuevos</label>
          <input
            type="file"
            className="form-control"
            multiple
            required
            ref={fileInput}
          />
        </div>
        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <button className={"btn btn-success"}>Agregar</button>
          <Link to={-1} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SubirArchivo;
