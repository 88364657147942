import React, { useContext, useState } from "react";
import styles from "../agregar/AgregarFicha.module.css";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { ApiContext } from "../../../context/api/ApiProvider";
import { ModalContext } from "../../../context/modal/ModalProvider";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/AuthProvider";

const ModificarFicha = () => {
  const { ficha, fichaCodigo, fichaDireccion, fichaTipo, updateData } =
    useOutletContext();
  const api = useContext(ApiContext);
  const [codigo, setCodigo] = useState(fichaCodigo);
  const [direccion, setDireccion] = useState(fichaDireccion);
  const [tipo, setTipo] = useState(fichaTipo);
  const { showModal, closeModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    api.post(
      "/fichas/modificar",
      {
        id: ficha,
        codigo,
        direccion,
        tipo,
      },
      (res) => {
        if (res.data.success) {
          toast.success("Ficha modificada con éxito");
          navigate("/inicio/fichas");
          updateData();
        } else if (res.data.error && res.data.error == "Ficha ya existe") {
          showModal("Error", "Ya existe una ficha con ese código y tipo");
        } else {
          toast.error("Error al modificar la ficha");
        }
      }
    );
  }
  return (
    <div className={styles.background}>
      <form
        className={styles.modal + " p-3"}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3>Modificar Ficha {ficha}</h3>
        <div className={styles.hr}></div>

        <div className={styles.form}>
          <label className="h6">Tipo</label>
          <select
            className="form-control"
            defaultValue={fichaTipo}
            value={tipo}
            onChange={(e) => {
              setTipo(e.target.value);
            }}
          >
            <option>Inquilino</option>
            <option>Propietario</option>
            {user.AdmGC == 1 ? (
              <>
                {" "}
                <option>Gastos Comunes A</option>
                <option>Gastos Comunes I</option>
                <option>Gastos Comunes P</option>
              </>
            ) : (
              ""
            )}
          </select>
          <label className="h6 pt-3">Código</label>
          <input
            type="text"
            className={"form-control"}
            defaultValue={fichaCodigo}
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
            required
          />
          <label className="h6 pt-3">Dirección</label>
          <input
            type="text"
            className={"form-control"}
            defaultValue={fichaDireccion}
            value={direccion}
            onChange={(e) => {
              setDireccion(e.target.value);
            }}
            required
          />
        </div>
        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <button className={"btn btn-warning"}>Modificar</button>
          <Link to={"/inicio/fichas"} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ModificarFicha;
