import React from "react";
import styles from "./AgregarUsuario.module.css";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { ApiContext } from "../../../context/api/ApiProvider";
import { toast } from "react-toastify";

const AgregarUsuario = () => {
  const [tipo, setTipo] = React.useState(0);
  const [nombre, setNombre] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [password, setPassword] = React.useState("");
  const api = React.useContext(ApiContext);
  const navigate = useNavigate();
  const { updateData } = useOutletContext();

  function handleSubmmit(e) {
    e.preventDefault();
    api.post(
      "/usuarios/add",
      { tipo, nombre, email, telefono, password },
      (res) => {
        if (res.data.success) {
          toast.success("Usuario agregado correctamente");
          updateData();
          navigate("/inicio/usuarios");
        } else if (res.data.error) {
          toast.error(res.data.error);
        }
      }
    );
  }

  return (
    <div className={styles.background}>
      <form
        className={styles.modal + " p-3"}
        onSubmit={(e) => handleSubmmit(e)}
      >
        <h3>Agregar Usuario</h3>
        <div className={styles.hr}></div>

        <div className={styles.form}>
          <label className="h6">Tipo</label>
          <select
            className="form-control"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            required
          >
            <option value={0}>Usuario</option>
            <option value={1}>Administrador</option>
          </select>
          <label className="h6 pt-3">Nombre</label>
          <input
            type="text"
            className={"form-control"}
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <label className="h6 pt-3">Email</label>
          <input
            type="text"
            className={"form-control"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            minLength={3}
          />
          <label className="h6 pt-3">Teléfono</label>
          <input
            type="text"
            className={"form-control"}
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            required
          />
          <label className="h6 pt-3">Contraseña</label>
          <input
            type="text"
            className={"form-control"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            minLength={3}
          />
        </div>
        <div className={styles.hr + " mt-3 mb-3"}></div>
        <div className={"d-flex flex-row  justify-content-end"}>
          <button className={"btn btn-success"} type="submit">
            Agregar
          </button>
          <Link to={"/inicio/usuarios"} className={"btn btn-danger ms-3"}>
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default AgregarUsuario;
